
.title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'Yu Gothic', YuGothic, 'MS PGothic', Osaka, arial, sans-serif;
    
}

.row {
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.Box {
    margin-left: 10px;
    overflow-y: scroll;
    height: 100%;
}

.detail {
    font-size: 20px;
    margin-bottom: 0;
    color:rgb(51, 51, 51);
    flex:end;
    text-decoration: none;
    
}
@media (max-width: 400px){
    .pane1 {
        overflow-y: auto;
        max-height: 50vh;
        min-width: 100px;
        margin: 0px;
        margin: 0;
    }
}
@media (min-width: 400px){
    .pane1 {
        overflow-y: auto;
        max-height: 100vh;
        min-width: 100px;
        margin: 0px;
        margin: 0;
    }
}
.pane1::-webkit-scrollbar{
    display: none;
}

.pane2 {
    overflow-y: auto;
    max-height: 96vh;
    padding:0px
}
.pane2::-webkit-scrollbar{
    display: none;
    padding:0px
}

.test {
    width: 100vw;
    margin:0 ;
    padding:0;
}

.content {
    padding-left: 15px;
    margin-right: 0px;
    margin-top: 10px;
}

.media{
    padding-top:10px;
    padding-bottom:10px;
    padding-right:10px;
    padding-left:10px;
}

/* .object {
    animation: fadein 2s forwards;
  }
  
  @keyframes fadein {
    0% {
       opacity: 0;
       transform: translateX(-50px);
    }
    100% {
       opacity: 1;
       transform: translateX(10px);
    }
  } */

.fade-in-section {
opacity: 0;
transform: translateY(20vh);
visibility: hidden;
transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
opacity: 1;
transform: none;
visibility: visible;
}