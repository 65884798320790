.code {
    white-space: pre-wrap;
    text-align:left
}

.box {
    border-radius: 16px;
}

.card {
    size: 5;
    border: 1;
    border-radius: '20px';
}
.codeText {
  font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'Yu Gothic', YuGothic, 'MS PGothic', Osaka, arial, sans-serif;
  padding-left: 1px;
  font-size: 12px;
  width: 100%;
  padding: 1em;/* 内側の余白 */
  margin: 0 0 1.5em;/* 外側の余白 */
  background:rgb(19, 19, 19);/* 背景色（背景を黒に） */
  color: #11ff00;/*文字色（文字を白に） */
    
}

.text {
  font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'Yu Gothic', YuGothic, 'MS PGothic', Osaka, arial, sans-serif;
  padding-left: 10px;
  font-size: 12px;
  width: 100%;
  padding: 1em;/* 内側の余白 */
  margin: 0 0 1.5em;/* 外側の余白 */
  color: #37463a;/* 文字色（文字を白に） */
}


.rightScreen {
  max-height: 100%;
  background-color: aqua;
}