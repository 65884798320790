
#header {
  background-color: transparent;
  /*Opacity start*/
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=80);
  -moz-opacity: 0.80;
  -khtml-opacity: 0.8;
  opacity: 0.9;
  /*Opacity end*/
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index:100;
}
#header #headerContent {
  margin: 100px;
}
@media (max-width: 400px){
  #header p {
    letter-spacing: .05em;
    font-family: Futura, Helvetica, 'Yu Gothic Medium' ;
    margin: 0px 0 0;
    font-size: 20px;
    color: #ff0000;
    -webkit-text-stroke: 1px #ff0000;
    font-weight: 700; 
  }
}
@media (min-width: 400px){
  #header p {
    letter-spacing: .05em;
    font-family: Futura, Helvetica, 'Yu Gothic Medium' ;
    margin: 0px 0 0;
    font-size: 50px;
    color: #ff0000;
    -webkit-text-stroke: 1px #ff0000;
    font-weight: 700; 
  }
}
#footer{
    width: 100%;
    background-color: transparent;
    text-align: center;

    position: sticky;/*←絶対位置*/
    bottom: 0; /*下に固定*/
}

#footer .wrapper {
    margin: 0 auto;
    width: 100%;
  }
  
  #footer p {
    font-family: Futura, Helvetica, 'Yu Gothic Medium';
    margin: 0px 0 0;
    font-size: 20px;
    color: rgb(255, 255, 255);
    color: transparent;
    -webkit-text-stroke: 1px #ff0000;
  }

.just {
  font-family: Futura, Helvetica, 'Yu Gothic Medium' ;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.deploy {
  padding-top: 10px;
}

.marker{
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.marker::after{
  content:"";
  width: 0%;    /*最初はwidth:0にする*/
  height: 25px;
  background: #fbff00;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
  transition: all .3s;  /*アニメーションの長さ*/
}	
.marker:hover::after{
  width: 100%;    /*hover時に100%で広がる*/
}